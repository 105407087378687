/**
 * Exporting all the variables depending if we are in develop or in a brand specific branch
 */

module.exports = {
  maxIddleTime: 1800000,
  internalClientName: 'dct',
  name: 'Dutch Clean Tech',
  pescheckEnabled: false,
  paymentServiceProvider: 'Custom',
  opp: {
    source: 'manual',
  },
  scssVariables: './src/scss/variables/brands/_dct.scss',
  functionality: {
    enableTradingBoard: false,
    bulkImport: true,
    includeOpenPaymentsInAssetExport: false,
    checkoutSessionEnabled: false,
    taxStatementYears: 3,
    languageChoice: {
      enableLanguageChoice: true,
      languages: ['nl', 'en'],
      defaultLanguage: 'nl',
    },
    modules: {
      asset: {
        requiredFields: [
          'name',
          'city',
          'country',
          'startDateTime',
          'endDateTime',
          'euroMin',
          'totalValueEuro',
          'sharePrice',
          'emissionCost',
          'dividendsFormat',
        ],
        fileKeyNames: ['images', 'floorPlanImages', 'prospectus', 'brochure'],
      },
    },
    createAsset: {
      showLocationToggle: false,
      subscriptionDocEnabled: false,
      secondaryDocEnabled: false,
      assetTermsOrLoanAgreementEnabled: false,
      additionalFilesForInvestmentsEnabled: true,
    },
    userDetails: {
      preferredLanguage: false,
    },
    createUser: {
      initialsRequired: false,
      identifierEnabled: true,
      riskClassificationRequired: false,
      kvkRequired: false,
      bankAccountOwnerRequired: false,
    },
    users: {
      pdfDownloadFunctionName: 'generateDividendsPDF',
    },
    investments: {
      fractionalSharesEnabled: true,
      earningsEnabled: false,
      repaymentsEnabled: false,
      loansTabEnabled: true,
    },
    payments: {
      cancellableEnabled: true,
      endingDisabled: false,
      endingDaySelector: false,
      markAsRequestedEnabled: false,
    },
    downloadFundInvestors: {
      requiredFields: {
        customId: { fieldName: 'customId', origin: 'investor', field: 'customId' },
        name: { fieldName: 'name', origin: 'investor', field: 'name' },
        surname: { fieldName: 'surname', origin: 'investor', field: 'surname' },
        email: { fieldName: 'email', origin: 'investor', field: 'email' },
        bankAccount: { fieldName: 'IBAN', origin: 'investor', field: 'bankAccount' },
        boughtSharesTotal: { fieldName: 'boughtSharesTotal', origin: 'investment', field: 'boughtSharesTotal' },
        paidEuroTotal: { fieldName: 'paidEuroTotal', origin: 'investment', field: 'paidEuroTotal' },
      },
    },
    investorPortal: {
      availableFundCard: {
        divFormatTypeEnabled: false,
      },
    },
  },
};
