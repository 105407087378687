import { Module } from 'vuex';
import to from 'await-to-js';
import moment from 'moment';
import BigNumber from 'bignumber.js';
import { State } from '@/models/State';
import { last } from 'lodash';
import { bloqifyFirestore, firebase, bloqifyFunctions, bloqifyStorage } from '@/boot/firebase';
import { Counts, DataContainerStatus } from '@/models/Common';
import { Asset } from '@/models/assets/Asset';
import { InvestmentRepayment } from '@/models/assets/Repayments';
import { Valuation } from '@/models/assets/Valuation';
import { BusinessInvestor, Investor } from '@/models/users/User';
import { PaymentStatus, PaymentProvider, Investment, Payment } from '@/models/investments/Investment';
import { Vertebra, generateState, mutateState } from '@/store/utils/skeleton';
import axios from 'axios';
import { roundNumber } from '@/store/utils/numbers';
import { generateFileMd5Hask } from '@/store/utils/files';
import { assetChecks } from './asset';
import { CustomBatch } from '../utils/customBatch';

const SET_PAYMENT = 'SET_PAYMENT';

// Payload types
export interface CreatePaymentPayload {
  /** € */
  amount: number;
  assetId: string;
  investorId: string;
  paymentDateTime: number;
  dividendsFormat?: Payment['dividendsFormat'];
  endDateTime?: number;
  type?: string;
  comment?: string;
  sendEmail?: boolean;
  shareValue: number;
}
export interface CreateTransferPaymentPayload {
  originInvestorId: string;
  destinyInvestorId: string;
  newBoughtSharesTotal: number;
  assetId: string;
  newPaidEuroTotal: number;
  transferDate: number;
  inputDividens: Payment['dividendsFormat'];
}
export type UpdatePaymentPayload = CreatePaymentPayload & {
  investmentId: string;
  paymentId: string;
};

export default {
  state: generateState(),
  mutations: {
    [SET_PAYMENT](
      state,
      { status, payload, operation }: { status: DataContainerStatus; payload?: unknown; operation: string },
    ): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async createPayment(
      { commit },
      {
        amount,
        assetId,
        investorId,
        paymentDateTime,
        dividendsFormat,
        endDateTime: paymentEndDateTime,
        type,
        comment,
        sendEmail,
        shareValue,
      }: CreatePaymentPayload,
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'createPayment' });

      const [transactionError, transactionSuccess] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<{ investmentId: string; paymentId: string }> => {
          // Collection references
          const insertedAssetRef = bloqifyFirestore.collection('assets').doc(assetId);
          const investmentsRef = bloqifyFirestore.collection('investments');
          const investorRef = bloqifyFirestore.collection('investors').doc(investorId);
          const paymentDate = firebase.firestore.Timestamp.fromMillis(paymentDateTime);
          const countsRef = bloqifyFirestore.collection('settings').doc('counts');
          let investmentRef = investmentsRef.doc();
          let paymentRef = investmentRef.collection('payments').doc();
          let sharesToReserve = 0;

          const [readAssetError, readAssetSuccess] = await to(transaction.get(insertedAssetRef));
          if (readAssetError || !readAssetSuccess?.exists) {
            throw readAssetError || Error('Asset not found.');
          }
          const asset = readAssetSuccess.data() as Asset;

          if (!assetChecks(asset)) {
            throw Error('The asset you are trying to add the payment to has invalid fields.');
          }

          // Get active valuations before payment date
          const [getValuationsError, getValuations] = await to(
            insertedAssetRef
              .collection('valuations')
              .where('deleted', '==', false)
              .where('applyDateTime', '<=', paymentDate)
              .orderBy('applyDateTime', 'desc')
              .get(),
          );
          if (getValuationsError) {
            throw getValuationsError;
          }
          const valuations = getValuations.docs.map((doc): Valuation => doc.data() as Valuation);

          const [readInvestorError, readInvestorSuccess] = await to(transaction.get(investorRef));
          if (readInvestorError || !readInvestorSuccess?.exists) {
            throw readInvestorError || Error('Investor not found.');
          }

          const investor = readInvestorSuccess.data() as Investor;

          const {
            sharePrice,
            euroMax,
            sharesAvailable,
            emissionCost,
            endDateTime,
            returnsAfterEnd,
            fixedDividends,
            startDateTime,
            premium,
            sharesEnding,
            sharesReserved,
            enableFractionalInvesting,
          } = asset;

          // if asset has valuation let's use it to calculate the sharePrice otherwise use sharePrice property from asset
          const sharePriceCalculated = enableFractionalInvesting ? shareValue : valuations[0]?.sharePrice || sharePrice;

          if (!enableFractionalInvesting && amount % sharePriceCalculated !== 0) {
            throw Error(
              `The amount has to be a total of shares bought (price per share: ${sharePriceCalculated} eur).`,
            );
          }

          const sharesDiff = new BigNumber(amount).dividedBy(sharePriceCalculated).toNumber();
          if (sharesDiff > sharesAvailable) {
            if (
              sharesDiff <=
              new BigNumber(sharesAvailable)
                .plus(sharesEnding || 0)
                .minus(sharesReserved || 0)
                .toNumber()
            ) {
              sharesToReserve = new BigNumber(sharesDiff).minus(sharesAvailable).toNumber();
            } else {
              throw Error('The asset does not have this many available shares.');
            }
          }

          // Client side we cannot use transaction to get DocumentQueries
          const [getInvestmentError, getInvestmentSuccess] = await to(
            investmentsRef
              .where('deleted', '==', false)
              .where('asset', '==', insertedAssetRef)
              .where('investor', '==', investorRef)
              .get(),
          );
          if (getInvestmentError) {
            throw getInvestmentError;
          }

          const foundInvestments = !getInvestmentSuccess?.empty;
          const investmentDoc = foundInvestments ? getInvestmentSuccess.docs[0] : undefined;
          // let firstInvestment = false;

          if (foundInvestments) {
            investmentRef = investmentDoc!.ref;
            const [getPaymentsError, getPaymentsSuccess] = await to(investmentRef.collection('payments').get());
            if (getPaymentsError) {
              throw getPaymentsError;
            }
            if (getPaymentsSuccess.empty) {
              throw Error('There was an error retrieving previous investments information.');
            }
            // firstInvestment = !getPaymentsSuccess.docs.some(
            //   (snap): boolean => snap.get('providerData.status') === PaymentStatus.Paid,
            // );

            // Reset paymentRef
            paymentRef = investmentRef.collection('payments').doc();
          }

          if (euroMax && amount > euroMax) {
            throw Error(`The amount has to be less than the maximum (${euroMax} eur).`);
          }

          if (startDateTime && paymentDate < startDateTime) {
            throw Error('The date of the payment cannot be earlier than the start date of the selected asset');
          }

          let years = 0;
          if (fixedDividends) {
            const numberedEndDateTime = endDateTime?.toMillis();
            // The paymentDateTime should already be in milliseconds as it comes from the frontend
            const paymentDateFormatted = moment(paymentDateTime);
            const yearsTemp = moment(numberedEndDateTime).diff(paymentDateFormatted, 'years');

            // If no years remaining, put 1
            years = yearsTemp > 0 ? yearsTemp : 1;
            // This condition should happen in all scenarios when not premium even or not loan.
            // The latter condition is introduced with ZIB where we have the division of asset types.
            // Loan has only interest rate, so no div format
            // Check for missing div format only if not sending endDateTime because then we construct it
          } else if ((!premium || asset.fundType !== 'loan') && !endDateTime && !dividendsFormat) {
            throw Error('Missing dividends format.');
          }

          const dateNow = firebase.firestore.Timestamp.now();

          // Adding the emission cost on top of the euro amount
          const amountWithEmissionCost = new BigNumber(100).plus(emissionCost).dividedBy(100).times(amount).toNumber();

          const newDividensFormat: [string, number] | undefined =
            !asset.premium && asset.fixedDividends ? [years.toString(), returnsAfterEnd || 0] : dividendsFormat;

          if (newDividensFormat === undefined) {
            throw Error('Missing dividends format.');
          }

          // Cannot create a payment dated before last earning/cost
          // get asset earning
          const [getAssetEarningsError, getAssetEarningsSuccess] = await to(
            bloqifyFirestore
              .collection('assets')
              .doc(assetId)
              .collection('assetEarnings')
              .where('deleted', '==', false)
              .orderBy('earningDateTime', 'desc')
              .get(),
          );

          if (getAssetEarningsError) {
            throw Error('Error retrieving the asset earnings.');
          }

          // get asset costs
          const [getAssetCostsError, getAssetCostsSuccess] = await to(
            bloqifyFirestore
              .collection('assets')
              .doc(assetId)
              .collection('assetCosts')
              .where('deleted', '==', false)
              .orderBy('costDateTime', 'desc')
              .get(),
          );

          if (getAssetCostsError) {
            throw Error('Error retrieving the asset costs.');
          }

          const lastEarningDate = getAssetEarningsSuccess.docs[0]?.get('earningDateTime')?.toMillis() || 0;
          const lastCostDate = getAssetCostsSuccess.docs[0]?.get('costDateTime')?.toMillis() || 0;

          if (lastEarningDate > paymentDate.toMillis() || lastCostDate > paymentDate.toMillis()) {
            if (lastEarningDate > lastCostDate) {
              throw Error(
                `Cannot create a payment dated before the last earning date ${moment(lastEarningDate).format('DD/MM/YYYY')}.`,
              );
            } else
              throw Error(
                `Cannot create a payment dated before the last cost date ${moment(lastCostDate).format('DD/MM/YYYY')}.`,
              );
          }

          const newPayment: Payment = {
            asset: insertedAssetRef,
            investment: investmentRef,
            investor: investorRef,
            createdDateTime: dateNow,
            paymentDateTime: paymentDate,
            id: paymentRef.id,
            provider: PaymentProvider.Custom,
            updatedDateTime: dateNow,
            deleted: false,
            dividendsFormat: newDividensFormat,
            providerData: {
              id: paymentRef.id,
              amount: {
                currency: 'EUR',
                value: roundNumber(amountWithEmissionCost, 2, BigNumber.ROUND_UP),
              },
              metadata: {
                uid: investorId,
                euroAmount: Number(amount),
                sharesAmount: new BigNumber(Number(amount)).dividedBy(sharePriceCalculated).toNumber(),
                investmentId: investmentRef.id,
                assetId: insertedAssetRef.id,
                paymentId: paymentRef.id,
              },
              status: PaymentStatus.Open,
            },
            ...(paymentEndDateTime && {
              endDateTime: firebase.firestore.Timestamp.fromMillis(paymentEndDateTime),
            }),
            ...(type && { type }),
            ...(comment && { comment }),
          };

          if (sharesToReserve) {
            const [getEndingPaymentsError, getEndingPayments] = await to(
              bloqifyFirestore
                .collectionGroup('payments')
                .where('asset', '==', insertedAssetRef)
                .where('deleted', '==', false)
                .where('scheduleEnding', '==', true)
                .orderBy('ended', 'asc')
                .get(),
            );
            if (getEndingPaymentsError) {
              throw getEndingPaymentsError;
            }
            const endingPayments = getEndingPayments.docs.map(
              (payment): Payment & { ref: firebase.firestore.DocumentReference } => ({
                ...(payment.data() as Payment),
                ref: payment.ref,
              }),
            );

            let remainingShares = sharesToReserve;
            const requiredPayments = endingPayments.filter((endPayment): boolean => {
              if (remainingShares > 0) {
                const sharesFree = new BigNumber(endPayment.providerData.metadata.sharesAmount)
                  .minus(endPayment.sharesReserved || 0)
                  .toNumber();
                if (sharesFree > 0) {
                  remainingShares -= sharesFree;
                  return true;
                }
                return false;
              }
              return false;
            });
            if (remainingShares > 0 || !requiredPayments.length) {
              throw Error('Not enought ending payments to fill the payment, asset counters error');
            }
            const lastEndedTime = last(requiredPayments)!.ended!;
            if (lastEndedTime.valueOf() > paymentDate.valueOf()) {
              throw Error(
                'Not enought shares available for the specified date, ' +
                  `the payment date should be ${moment(lastEndedTime.toDate()).format('DD/MM/YYYY')} or later`,
              );
            }
            remainingShares = sharesToReserve;
            const usedEndPayments: Payment['usedEndPayments'] = [];
            requiredPayments.forEach((requirePayment): void => {
              const sharesFree = new BigNumber(requirePayment.providerData.metadata.sharesAmount)
                .minus(requirePayment.sharesReserved || 0)
                .toNumber();
              let usedShares = 0;
              if (sharesFree > remainingShares) {
                usedShares = remainingShares;
              } else {
                usedShares = sharesFree;
              }
              remainingShares -= usedShares;
              usedEndPayments.push({
                investmentId: requirePayment.ref.parent.parent!.id,
                paymentId: requirePayment.ref.id,
                amount: usedShares,
              });
              transaction.update(requirePayment.ref, {
                sharesReserved: firebase.firestore.FieldValue.increment(usedShares),
              });
            });
            newPayment.usedEndPayments = usedEndPayments;
          }

          // Setting new investment or updating old one
          if (!foundInvestments) {
            const investment: Omit<Investment, 'id'> = {
              deleted: false,
              asset: insertedAssetRef,
              createdDateTime: dateNow,
              updatedDateTime: dateNow,
              investor: investorRef,
              openPayments: 1,
              ...(investor.identifier && { identifier: investor.identifier }),
            };

            transaction.set(investmentRef, investment);
          } else {
            transaction.update(investmentRef, {
              openPayments: firebase.firestore.FieldValue.increment(1),
              updatedDateTime: dateNow,
            });
          }

          transaction.set(paymentRef, newPayment);
          transaction.update(insertedAssetRef, {
            sharesAvailable: new BigNumber(asset.sharesAvailable).minus(sharesDiff).plus(sharesToReserve).toNumber(),
            sharesReserved: new BigNumber(asset.sharesReserved || 0).plus(sharesToReserve).toNumber(),
            updatedDateTime: dateNow,
          } as Asset);
          transaction.set(
            countsRef,
            {
              openPayments: firebase.firestore.FieldValue.increment(1),
              updatedDateTime: dateNow,
            } as Counts,
            { merge: true },
          );

          if (sendEmail) {
            const [sendEmailError] = await to(
              bloqifyFunctions.httpsCallable('sendSharesEmail')({
                lang: 'nl',
                investor: {
                  name: investor.name,
                  middleName: investor.middleName,
                  surname: investor.surname,
                  email: investor.email,
                  gender: investor.gender,
                  houseNumber: investor.houseNumber,
                  streetAddress: investor.streetAddress,
                  postalCode: investor.postalCode,
                  companyId: (investor as BusinessInvestor).companyId,
                  director: (investor as BusinessInvestor).director,
                  kvk: (investor as BusinessInvestor).kvk,
                  city: investor.city,
                  country: investor.country,
                  dateOfBirth: investor.dateOfBirth.toDate(),
                  placeOfBirth: investor.placeOfBirth,
                  telephone: investor.telephone,
                  kycMethod: investor.kycMethod,
                },
                asset: {
                  name: asset.name,
                  sharePrice: asset.sharePrice,
                  companyNumber: asset.companyNumber,
                  email: asset.email,
                  telephone: asset.telephone,
                  houseNumber: asset.houseNumber,
                  street: asset.street,
                  postalCode: asset.postalCode,
                  city: asset.city,
                  country: asset.country,
                  effectType: asset.effectType,
                  stak: asset.stak,
                  stakCompanyName: asset.stakCompanyName || '',
                },
              }),
            );
            if (sendEmailError) {
              console.error(sendEmailError);
            }
          }

          // Return the target
          return { investmentId: investmentRef.id, paymentId: paymentRef.id };
        }),
      );
      if (transactionError) {
        return commit(SET_PAYMENT, {
          status: DataContainerStatus.Error,
          payload: transactionError,
          operation: 'createPayment',
        });
      }

      return commit(SET_PAYMENT, {
        status: DataContainerStatus.Success,
        payload: transactionSuccess,
        operation: 'createPayment',
      });
    },
    async updatePayment(
      { commit },
      {
        amount,
        assetId,
        investorId,
        paymentDateTime,
        investmentId: sourceInvestmentId,
        paymentId: sourcePaymentId,
        dividendsFormat,
        endDateTime: paymentEndDateTime,
        type,
        comment,
        shareValue,
      }: UpdatePaymentPayload,
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'updatePayment' });

      const [transactionError, transactionSuccess] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<{ investmentId: string; paymentId: string }> => {
          // Collection references
          const assetsRef = bloqifyFirestore.collection('assets');
          const investmentsRef = bloqifyFirestore.collection('investments');
          const investorsRef = bloqifyFirestore.collection('investors');

          const insertedAssetRef = assetsRef.doc(assetId);
          const investmentRef = investmentsRef.doc(sourceInvestmentId);
          const investorRef = investorsRef.doc(investorId);
          const paymentRef = investmentRef.collection('payments').doc(sourcePaymentId);
          const paymentDate = firebase.firestore.Timestamp.fromMillis(paymentDateTime);

          // Get active valuations before payment date
          const [getValuationsError, getValuations] = await to(
            insertedAssetRef
              .collection('valuations')
              .where('deleted', '==', false)
              .where('applyDateTime', '<=', paymentDate)
              .orderBy('applyDateTime', 'desc')
              .get(),
          );
          if (getValuationsError) {
            throw getValuationsError;
          }
          const valuations = getValuations.docs.map((doc): Valuation => doc.data() as Valuation);

          // References that will be modified depending on the case for the transaction update/set zone
          let newPaymentRef = paymentRef;
          let newInvestmentRef = investmentRef;

          const [readSourceInvestmentError, sourceInvestmentSuccess] = await to(transaction.get(investmentRef));
          if (readSourceInvestmentError) {
            throw readSourceInvestmentError;
          } else if (!sourceInvestmentSuccess.exists) {
            throw Error('Investment not found.');
          }

          const sourceInvestment = sourceInvestmentSuccess.data() as Investment;

          const oldAssetId = sourceInvestment.asset.id;
          const differentAsset = oldAssetId !== assetId;
          const differentInvestor = sourceInvestment.investor.id !== investorId;
          const differentInvestment = differentAsset || differentInvestor;
          let targetInvestment: undefined | { id: string; data: Investment };

          // Different investment means we have to check if there is an investment already there
          if (differentInvestment) {
            const [readTargetInvestmentError, targetInvestmentSuccess] = await to(
              investmentsRef
                .where('deleted', '==', false)
                .where('asset', '==', assetsRef.doc(assetId))
                .where('investor', '==', investorsRef.doc(investorId))
                .get(),
            );
            if (readTargetInvestmentError) {
              throw readTargetInvestmentError;
            }

            // Assigning the target investment
            if (!targetInvestmentSuccess.empty) {
              targetInvestment = {
                id: targetInvestmentSuccess.docs[0].id,
                data: targetInvestmentSuccess.docs[0].data() as Investment,
              };
            }
          }

          const [readSourcePaymentError, sourcePaymentSuccess] = await to(transaction.get(paymentRef));
          if (readSourcePaymentError) {
            throw readSourcePaymentError;
          } else if (!sourcePaymentSuccess?.exists) {
            Error('Payment not found.');
          }

          const sourcePayment = sourcePaymentSuccess.data() as Payment;

          if (sourcePayment.deleted || sourcePayment.ended) {
            throw Error('Cannot modify an ended or deleted payment.');
          }

          if (
            sourcePayment.providerData.status !== PaymentStatus.Open &&
            sourcePayment.providerData.status !== PaymentStatus.Requested &&
            sourcePayment.providerData.status !== PaymentStatus.Paid
          ) {
            throw Error('Cannot modify a payment which is not open, requested or paid.');
          }

          if (sourcePayment.usedEndPayments) {
            throw Error('Cannot modify a reserved payment (used future ending payments)');
          }
          // Disable the modification of a payment with a From field
          if (sourcePayment.from) {
            throw Error('Cannot modify a transfer payment.');
          }

          const [readInvestorError, readInvestorSuccess] = await to(transaction.get(investorRef));
          if (readInvestorError || !readInvestorSuccess?.exists) {
            throw readInvestorError || Error('Investor not found.');
          }
          const investor = readInvestorSuccess.data() as Investor;

          const [readSourceAssetError, sourceAssetSuccess] = await to(transaction.get(insertedAssetRef));
          if (readSourceAssetError) {
            throw readSourceAssetError;
          } else if (!sourceAssetSuccess?.exists) {
            Error('Asset not found.');
          }

          const [reatedSourceAssetError, oldAssetSuccess] = await to(transaction.get(assetsRef.doc(oldAssetId)));
          if (reatedSourceAssetError) {
            throw reatedSourceAssetError;
          } else if (!oldAssetSuccess?.exists) {
            Error('Target asset not found.');
          }

          const targetAsset = sourceAssetSuccess.data() as Asset;
          const oldAsset = oldAssetSuccess.data() as Asset;

          // Check both (if necessary) assets have all fields correct
          if (oldAsset && !assetChecks(oldAsset) && differentAsset && !assetChecks(targetAsset)) {
            throw Error('The asset you are trying to add the payment to has invalid fields.');
          }

          // Select the correct asset
          const {
            sharePrice,
            euroMax,
            emissionCost,
            endDateTime,
            returnsAfterEnd,
            fixedDividends,
            startDateTime,
            enableFractionalInvesting,
          } = targetAsset || oldAsset;

          // if asset has valuation let's use it to calculate the sharePrice otherwise use sharePrice property from asset
          const sharePriceCalculated = enableFractionalInvesting ? shareValue : valuations[0]?.sharePrice || sharePrice;

          if (!enableFractionalInvesting && amount % sharePriceCalculated !== 0) {
            throw Error(
              `The amount has to be a total of shares bought (price per share: ${sharePriceCalculated} eur).`,
            );
          }

          // If there was an old payment we might need these values to restore the correct amounts
          const oldPaymentAmount = Number(sourcePayment.providerData.metadata.euroAmount);
          const oldPaymentSharesAmount = Number(sourcePayment.providerData.metadata.sharesAmount);

          const amountIsDifferent = oldPaymentAmount !== amount;

          // If the asset is unchanged for a modify payment we calculate the difference of the new amount and the old one (can be negative)
          /** Shares */
          const newShares = new BigNumber(amount).dividedBy(sharePriceCalculated).toNumber();

          // If it's the same asset, the asset shares available (excluding the used by the current payment),
          // minus the new amount of shares, cannot be under 0.
          // If it's a different asset, we just need to check if the sharesAvaiable from the target minus the shares of the
          // payment coming in are minus 0.
          if (
            (!differentAsset &&
              newShares !== oldPaymentSharesAmount &&
              new BigNumber(targetAsset.sharesAvailable).plus(oldPaymentSharesAmount).minus(newShares).toNumber() <
                0) ||
            (differentAsset && new BigNumber(targetAsset.sharesAvailable).minus(newShares).toNumber() < 0)
          ) {
            throw Error('The asset does not have this many available shares.');
          }

          if (euroMax && amount > euroMax) {
            throw Error(`The amount has to be less than the maximum (${euroMax} eur).`);
          }

          if (
            sourcePayment.providerData.status !== PaymentStatus.Open &&
            sourcePayment.providerData.status !== PaymentStatus.Requested &&
            startDateTime &&
            paymentDate < startDateTime
          ) {
            throw Error('The date of the payment cannot be earlier than the start date of the selected asset');
          }

          let years = 0;
          if (fixedDividends) {
            const numberedEndDateTime = endDateTime?.toMillis();
            // The paymentDateTime should already be in milliseconds as it comes from the frontend
            const paymentDateFormatted = moment(paymentDateTime);
            const yearsTemp = moment(numberedEndDateTime).diff(paymentDateFormatted, 'years');

            // If no years remaining, put 1
            years = yearsTemp > 0 ? yearsTemp : 1;
          } else if (!endDateTime && !dividendsFormat) {
            throw Error('Missing dividends format.');
          }

          const dateNow = firebase.firestore.Timestamp.now();

          const newPayment: Payment = {
            ...sourcePayment,
            ...(sourcePayment.providerData.status === PaymentStatus.Paid && { paymentDateTime: paymentDate }),
            dividendsFormat: !fixedDividends ? dividendsFormat! : [years.toString(), returnsAfterEnd!],
            updatedDateTime: dateNow,
            ...(paymentEndDateTime && { endDateTime: firebase.firestore.Timestamp.fromMillis(paymentEndDateTime) }),
            ...(type && { type }),
            ...(comment && { comment }),
          };

          if (amountIsDifferent) {
            // Adding the emission cost on top of the euro amount
            const amountWithEmissionCost = new BigNumber(100)
              .plus(emissionCost)
              .dividedBy(100)
              .times(amount)
              .toNumber();

            newPayment.providerData.amount.value = roundNumber(amountWithEmissionCost, 2, BigNumber.ROUND_UP);
            newPayment.providerData.metadata.euroAmount = amount;
          }

          const sharesDiff = new BigNumber(newShares).minus(oldPaymentSharesAmount).toNumber();

          if (sharesDiff !== 0) {
            newPayment.providerData.metadata.sharesAmount = newShares;
          }

          /**
           * TRANSACTION UPDATE / SET ZONE
           */
          if (differentInvestment) {
            if (targetInvestment) {
              newInvestmentRef = investmentsRef.doc(targetInvestment.id);
              // Update target investment
              transaction.update(newInvestmentRef, {
                ...(newPayment.providerData.status !== PaymentStatus.Open &&
                  newPayment.providerData.status !== PaymentStatus.Requested && {
                    paidEuroTotal: new BigNumber(targetInvestment?.data.paidEuroTotal || 0).plus(amount).toNumber(),
                    boughtSharesTotal: new BigNumber(targetInvestment?.data.boughtSharesTotal || 0)
                      .plus(newShares)
                      .toNumber(),
                    paidPayments: firebase.firestore.FieldValue.increment(1),
                  }),
                ...((newPayment.providerData.status === PaymentStatus.Open ||
                  newPayment.providerData.status === PaymentStatus.Requested) && {
                  openPayments: firebase.firestore.FieldValue.increment(1),
                }),
                updatedDateTime: dateNow,
              } as Investment);

              // Create target payment
              newPaymentRef = investmentsRef.doc(targetInvestment.id).collection('payments').doc();
              transaction.set(newPaymentRef, {
                ...newPayment,
                investor: investorRef,
                investment: newInvestmentRef,
                asset: insertedAssetRef,
                id: newPaymentRef.id,
              } as Payment);
            } else {
              // Create target investment
              newInvestmentRef = investmentsRef.doc();
              const investment: Investment = {
                deleted: false,
                ...(newPayment.providerData.status !== PaymentStatus.Open &&
                  newPayment.providerData.status !== PaymentStatus.Requested && {
                    paidEuroTotal: Number(amount),
                    boughtSharesTotal: newShares,
                    paidPayments: 1,
                  }),
                ...((newPayment.providerData.status === PaymentStatus.Open ||
                  newPayment.providerData.status === PaymentStatus.Requested) && {
                  openPayments: 1,
                }),
                asset: insertedAssetRef,
                investor: investorRef,
                createdDateTime: sourceInvestment.createdDateTime,
                updatedDateTime: dateNow,
                ...(investor.identifier && { identifier: investor.identifier }),
              };
              transaction.set(newInvestmentRef, investment);

              // Create target payment
              newPaymentRef = newInvestmentRef.collection('payments').doc();
              transaction.set(newPaymentRef, {
                ...newPayment,
                investor: investorRef,
                asset: insertedAssetRef,
                investment: newInvestmentRef,
                id: newPaymentRef.id,
              } as Payment);
            }

            // Restore source asset
            transaction.update(assetsRef.doc(oldAssetId), {
              sharesAvailable: new BigNumber(oldAsset.sharesAvailable).plus(oldPaymentSharesAmount).toNumber(),
              updatedDateTime: dateNow,
            } as Asset);

            transaction.update(insertedAssetRef, {
              sharesAvailable: new BigNumber(targetAsset.sharesAvailable).minus(newShares).toNumber(),
              updatedDateTime: dateNow,
            } as Asset);

            // Delete old payment
            transaction.delete(paymentRef);

            // Check if there're still payments under sourceInvestment
            const paymentsRef = investmentRef.collection('payments');
            const [readInvestmentPaymentsError, investmentPaymentsSuccess] = await to(paymentsRef.get());
            if (readInvestmentPaymentsError) {
              throw readInvestmentPaymentsError;
            }
            const investmentPaymentsLength = investmentPaymentsSuccess.docs.length;

            // if there's only 1 payment left that will be moved into a new investment -> remove current investment
            // otherwise just decrease values (boughtSharesTotal, paidPayments, openPayments...)
            if (investmentPaymentsLength === 1) {
              // Soft delete and reset payments values
              transaction.update(investmentRef, {
                deleted: true,
                openPayments: 0,
                paidPayments: 0,
              });
            } else {
              transaction.update(investmentRef, {
                ...(newPayment.providerData.status !== PaymentStatus.Open &&
                  newPayment.providerData.status !== PaymentStatus.Requested && {
                    paidEuroTotal: new BigNumber(sourceInvestment?.paidEuroTotal || 0)
                      .minus(oldPaymentAmount)
                      .toNumber(),
                    boughtSharesTotal: new BigNumber(sourceInvestment?.boughtSharesTotal || 0)
                      .minus(oldPaymentSharesAmount)
                      .toNumber(),
                    paidPayments: firebase.firestore.FieldValue.increment(-1),
                  }),
                ...((newPayment.providerData.status === PaymentStatus.Open ||
                  newPayment.providerData.status === PaymentStatus.Requested) && {
                  openPayments: firebase.firestore.FieldValue.increment(-1),
                }),
                updatedDateTime: dateNow,
              } as Investment);
            }
          } else {
            /** € */
            const eAmountDiff = new BigNumber(amount).minus(oldPaymentAmount).toNumber();
            /** Shares */
            // Update target investment
            transaction.update(investmentRef, {
              ...(newPayment.providerData.status !== PaymentStatus.Open &&
                newPayment.providerData.status !== PaymentStatus.Requested && {
                  paidEuroTotal: new BigNumber(sourceInvestment?.paidEuroTotal || 0).plus(eAmountDiff).toNumber(),
                  boughtSharesTotal: new BigNumber(sourceInvestment?.boughtSharesTotal || 0)
                    .plus(sharesDiff)
                    .toNumber(),
                }),
              updatedDateTime: dateNow,
            } as Investment);

            // Update target payment
            transaction.update(paymentRef, newPayment);

            // Update target asset
            transaction.update(assetsRef.doc(assetId), {
              sharesAvailable: new BigNumber(oldAsset.sharesAvailable).minus(sharesDiff).toNumber(),
              updatedDateTime: dateNow,
            } as Asset);
          }

          // Return the target
          return { investmentId: newInvestmentRef.id, paymentId: newPaymentRef.id };
        }),
      );
      if (transactionError) {
        return commit(SET_PAYMENT, {
          status: DataContainerStatus.Error,
          payload: transactionError,
          operation: 'updatePayment',
        });
      }

      return commit(SET_PAYMENT, {
        status: DataContainerStatus.Success,
        payload: transactionSuccess,
        operation: 'updatePayment',
      });
    },
    async deletePayment(
      { commit },
      { investmentId, paymentId }: { investmentId: string; paymentId: string },
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'deletePayment' });
      const [transactionError] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const transactionBatch = new CustomBatch(transaction);
          // Set Refs
          const investmentRef = bloqifyFirestore.collection('investments').doc(investmentId);
          const paymentRef = investmentRef.collection('payments').doc(paymentId);
          const countsRef = bloqifyFirestore.collection('settings').doc('counts');
          const timeNow = firebase.firestore.Timestamp.now();

          // Fetch investment
          const [getInvestmentError, getInvestment] = await to(transaction.get(investmentRef));
          if (getInvestmentError || !getInvestment.exists) {
            throw getInvestmentError || Error('Error getting the investment.');
          }
          const investment = getInvestment.data() as Investment;

          // Fetch payment
          const [getPaymentError, getPayment] = await to(transaction.get(paymentRef));
          if (getPaymentError || !getPayment.exists) {
            throw getPaymentError || Error('Error getting the payment.');
          }
          const payment = getPayment.data() as Payment;

          // Fetch repayment
          let repaymentRef;
          if (payment.from instanceof firebase.firestore.DocumentReference) {
            repaymentRef = payment.from;
          }

          // Repayment
          if (repaymentRef) {
            const [repaymentError, repaymentSnapshot] = await to(transaction.get(repaymentRef));
            if (repaymentError) {
              throw new Error('Repayment reference not defined');
            }

            if (repaymentSnapshot && !repaymentError) {
              const repaymentData = repaymentSnapshot.data() as InvestmentRepayment;
              // Update counters investments
              transactionBatch.addOperation({
                ref: investmentRef,
                data: {
                  totalEuroRepayments: new BigNumber(investment.totalEuroRepayments || 0)
                    .minus(repaymentData.amount)
                    .toNumber(),
                  totalSharesRepayments: new BigNumber(investment.totalSharesRepayments || 0)
                    .minus(repaymentData.shares || 0)
                    .toNumber(),
                  updatedDateTime: timeNow,
                } as Investment,
              });

              // Delete repayment
              transactionBatch.addOperation({
                ref: repaymentRef,
                data: {
                  deleted: true,
                  updatedDateTime: timeNow,
                } as InvestmentRepayment,
              });
            } else {
              throw new Error('Repayment document not found');
            }
          }

          // Fetch asset
          const assetRef = investment.asset as firebase.firestore.DocumentReference;
          const [getAssetError, getAsset] = await to(transaction.get(assetRef));
          if (getAssetError || !getAsset.exists) {
            throw getAssetError || Error('Error getting the asset.');
          }
          const asset = getAsset.data() as Asset;

          // Checks
          if (payment.deleted) {
            throw Error('This payment was already deleted.');
          }
          if (payment.ended && payment.scheduleEnding && payment.sharesReserved) {
            throw Error('Can`t delete an end payment whose shares are already in use.');
          }

          const investmentUpdate: Partial<Investment> = {};

          // Get active payments
          const [paymentsError, paymentsSuccess] = await to(
            investmentRef.collection('payments').where('deleted', '==', false).get(),
          );
          if (paymentsError) {
            throw paymentsError;
          }

          const otherActivePaymentsExist = paymentsSuccess?.docs?.some((doc): boolean => doc.id !== paymentId);

          // If there are no other non-deleted payments besides the one with paymentId, delete the whole investment doc
          if (!otherActivePaymentsExist) {
            investmentUpdate.deleted = true;
          }

          let sharesReserved = 0;
          if (payment.usedEndPayments?.length) {
            const [updateUsedEndPaymentsError, updateUsedEndPayments] = await to(
              Promise.all(
                payment.usedEndPayments.map(async (usedEndPayment): Promise<number> => {
                  const usedEndPaymentRef = bloqifyFirestore
                    .collection('investments')
                    .doc(usedEndPayment.investmentId)
                    .collection('payments')
                    .doc(usedEndPayment.paymentId);
                  const [getUsedEndPaymentError, getUsedEndPayment] = await to(transaction.get(usedEndPaymentRef));
                  if (getUsedEndPaymentError || !getUsedEndPayment.exists) {
                    throw Error(getUsedEndPaymentError?.message || 'Error getting the used end payment.');
                  }
                  const usedEndPaymentData = getUsedEndPayment.data() as Payment;
                  if (usedEndPaymentData.scheduleEnding) {
                    transactionBatch.addOperation({
                      ref: usedEndPaymentRef,
                      data: {
                        sharesReserved: firebase.firestore.FieldValue.increment(-usedEndPayment.amount),
                      },
                    });
                    return usedEndPayment.amount;
                  }
                  return 0;
                }),
              ),
            );
            if (updateUsedEndPaymentsError) {
              throw updateUsedEndPaymentsError;
            }
            sharesReserved = updateUsedEndPayments.reduce((acum, val): number => acum + val);
          }

          // Only restore the share fields if the payment was open or requested and is now marked as paid.
          // Otherwise the paymentWebHook already took care of it
          if (
            [PaymentStatus.Open, PaymentStatus.Requested, PaymentStatus.Paid].includes(payment.providerData.status) &&
            !payment.ended
          ) {
            const amount = payment.providerData.metadata.euroAmount;
            const sharesAmount = payment.providerData.metadata.sharesAmount;

            transactionBatch.addOperation({
              ref: assetRef,
              data: {
                sharesAvailable: new BigNumber(asset.sharesAvailable)
                  .plus(sharesAmount)
                  .minus(sharesReserved)
                  .toNumber(),
                sharesReserved: new BigNumber(asset.sharesReserved || 0).minus(sharesReserved).toNumber(),
                sharesEnding: new BigNumber(asset.sharesEnding || 0)
                  .minus(payment.scheduleEnding ? sharesAmount : 0)
                  .toNumber(),
                updatedDateTime: timeNow,
              } as Asset,
            });

            investmentUpdate.deletedPayments = firebase.firestore.FieldValue.increment(1);
            investmentUpdate.updatedDateTime = timeNow;
            if ([PaymentStatus.Open, PaymentStatus.Requested].includes(payment.providerData.status)) {
              investmentUpdate.openPayments = firebase.firestore.FieldValue.increment(-1);
            }

            const countsUpdate: Partial<Counts> = {
              updatedDateTime: timeNow,
            };

            if (payment.providerData.status === PaymentStatus.Paid) {
              investmentUpdate.paidEuroTotal = new BigNumber(investment.paidEuroTotal || 0).minus(amount).toNumber();
              investmentUpdate.boughtSharesTotal = new BigNumber(investment.boughtSharesTotal || 0)
                .minus(sharesAmount)
                .toNumber();
              investmentUpdate.paidPayments = firebase.firestore.FieldValue.increment(-1);
              countsUpdate.paidPayments = firebase.firestore.FieldValue.increment(-1);
            } else if (
              payment.providerData.status === PaymentStatus.Open ||
              payment.providerData.status === PaymentStatus.Requested
            ) {
              investmentUpdate.openPayments = firebase.firestore.FieldValue.increment(-1);
              countsUpdate.openPayments = firebase.firestore.FieldValue.increment(-1);
            }

            transactionBatch.addOperation({
              ref: countsRef,
              data: countsUpdate,
            });
          }
          transactionBatch.addOperation({
            ref: investmentRef,
            data: investmentUpdate,
          });

          // Removing payment
          transactionBatch.addOperation({
            ref: paymentRef,
            data: {
              deleted: true,
              updatedDateTime: timeNow,
            } as Payment,
          });
          transactionBatch.commit();
        }),
      );
      if (transactionError) {
        return commit(SET_PAYMENT, {
          status: DataContainerStatus.Error,
          payload: transactionError,
          operation: 'deletePayment',
        });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, operation: 'deletePayment' });
    },
    async endPayment(
      { commit },
      { investmentId, paymentId, endDate }: { investmentId: string; paymentId: string; endDate?: number },
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'endPayment' });

      const [transactionError] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const timeNow = firebase.firestore.Timestamp.now();
          const endTime = endDate ? firebase.firestore.Timestamp.fromMillis(endDate) : timeNow;
          const endFuture = endTime > timeNow;
          const investmentRef = bloqifyFirestore.collection('investments').doc(investmentId);
          const paymentRef = investmentRef.collection('payments').doc(paymentId);

          const [readInvestment, readInvestmentSuccess] = await to(transaction.get(investmentRef));
          if (readInvestment || !readInvestmentSuccess?.exists) {
            throw readInvestment || Error('Error getting the investment.');
          }
          const investment = readInvestmentSuccess.data() as Investment;

          const assetRef = investment.asset as firebase.firestore.DocumentReference;

          const [readPayment, readPaymentSuccess] = await to(transaction.get(paymentRef));
          if (readPayment || !readPaymentSuccess?.exists) {
            throw readPayment || Error('Error getting the payment.');
          }

          const payment = readPaymentSuccess.data() as Payment;

          if (payment.ended) {
            throw Error('This payment was already ended.');
          }

          if (payment.deleted) {
            throw Error('Cannot end a deleted payment.');
          }
          if (payment.providerData.status !== PaymentStatus.Paid) {
            throw Error('You can only end paid payments.');
          }

          if (endTime.toDate() < payment.paymentDateTime!.toDate()) {
            throw new Error('The payment cannot be ended before the paid date.');
          }

          const [readAsset, readAssetSuccess] = await to(transaction.get(assetRef));
          if (readAsset || !readAssetSuccess?.exists) {
            throw readAsset || Error('Error getting the asset.');
          }

          const asset = readAssetSuccess.data() as Asset;

          if (asset.startDateTime && new Date(endDate || timeNow.toMillis()) < asset.startDateTime.toDate()) {
            throw new Error('The payment cannot be ended before the start date of the selected asset.');
          }

          // Only restore the share fields if the payment was already paid. Otherwise the paymentWebHook already took care of it
          if (payment.providerData.status === PaymentStatus.Paid && !payment.ended) {
            const amount = payment.providerData.metadata.euroAmount;
            const sharesAmount = payment.providerData.metadata.sharesAmount;

            // Update asset (only necessary if removing a payment with a paid status)
            transaction.update(assetRef, {
              sharesAvailable: new BigNumber(asset.sharesAvailable).plus(endFuture ? 0 : sharesAmount).toNumber(),
              sharesEnding: new BigNumber(asset.sharesEnding || 0).plus(endFuture ? sharesAmount : 0).toNumber(),
              updatedDateTime: timeNow,
            } as Asset);

            if (!endFuture) {
              // Update investment with new totals
              transaction.update(investmentRef, {
                paidEuroTotal: new BigNumber(investment.paidEuroTotal || 0).minus(amount).toNumber(),
                boughtSharesTotal: new BigNumber(investment.boughtSharesTotal || 0).minus(sharesAmount).toNumber(),
                updatedDateTime: timeNow,
              } as Investment);
            }
          }

          // Ending payment
          transaction.update(paymentRef, {
            ended: endDate ? firebase.firestore.Timestamp.fromMillis(endDate) : timeNow,
            scheduleEnding: endFuture,
            updatedDateTime: timeNow,
          });
        }),
      );
      if (transactionError) {
        return commit(SET_PAYMENT, {
          status: DataContainerStatus.Error,
          payload: transactionError,
          operation: 'endPayment',
        });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, operation: 'endPayment' });
    },
    async cancelPayment(
      { commit },
      { investmentId, paymentId }: { investmentId: string; paymentId: string },
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'cancelPayment' });

      const [cancelPaymentError] = await to(
        bloqifyFunctions.httpsCallable('cancelPayment')({ investmentId, paymentId }),
      );

      if (cancelPaymentError) {
        return commit(SET_PAYMENT, {
          status: DataContainerStatus.Error,
          payload: cancelPaymentError,
          operation: 'cancelPayment',
        });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, operation: 'cancelPayment' });
    },
    async markPaymentAsPaid(
      { commit },
      { investmentId, paymentId }: { investmentId: string; paymentId: string },
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'markPaymentAsPaid' });

      const [markPaymentAsPaidError] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const investmentRef = bloqifyFirestore.collection('investments').doc(investmentId);
          const paymentRef = investmentRef.collection('payments').doc(paymentId);

          const [readInvestment, readInvestmentSuccess] = await to(transaction.get(investmentRef));
          if (readInvestment || !readInvestmentSuccess?.exists) {
            throw readInvestment || Error('Error getting the investment.');
          }

          const investment = readInvestmentSuccess.data() as Investment;
          const assetRef = investment.asset as firebase.firestore.DocumentReference;

          const [readPayment, readPaymentSuccess] = await to(transaction.get(paymentRef));
          if (readPayment || !readPaymentSuccess?.exists) {
            throw readPayment || Error('Error getting the payment.');
          }

          const payment = readPaymentSuccess.data() as Payment;
          const amount = payment.providerData.metadata.euroAmount;
          const sharesAmount = payment.providerData.metadata.sharesAmount;

          if (payment.providerData.status === PaymentStatus.Paid) {
            throw Error('This payment is already with the status Paid.');
          }

          const [readAsset, readAssetSuccess] = await to(transaction.get(assetRef));

          if (readAsset || !readAssetSuccess?.exists) {
            throw readAsset || Error('Error getting the asset.');
          }

          const timeNow = firebase.firestore.Timestamp.now();

          // marking payment as Paid and ending it
          transaction.update(paymentRef, {
            providerData: {
              ...payment.providerData,
              status: PaymentStatus.Paid,
            },
          });

          const opened = [PaymentStatus.Open, PaymentStatus.Requested].includes(payment.providerData.status);
          // Update investment update time
          transaction.update(investmentRef, {
            paidEuroTotal: new BigNumber(investment.paidEuroTotal || 0).plus(amount).toNumber(),
            boughtSharesTotal: new BigNumber(investment.boughtSharesTotal || 0).plus(sharesAmount).toNumber(),
            paidPayments: firebase.firestore.FieldValue.increment(1),
            ...(opened && {
              openPayments: firebase.firestore.FieldValue.increment(-1),
            }),
            updatedDateTime: timeNow,
          } as Investment);
        }),
      );

      if (markPaymentAsPaidError) {
        return commit(SET_PAYMENT, {
          status: DataContainerStatus.Error,
          payload: markPaymentAsPaidError,
          operation: 'markPaymentAsPaid',
        });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, operation: 'markPaymentAsPaid' });
    },
    async markPaymentAsRequested(
      { commit },
      { investmentId, paymentId, lang }: { investmentId: string; paymentId: string; lang: string },
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'markPaymentAsRequested' });

      const [requestPaymentError] = await to(
        bloqifyFunctions.httpsCallable('requestPayment')({ mode: 'payment', investmentId, paymentId, lang }),
      );
      if (requestPaymentError) {
        return commit(SET_PAYMENT, {
          status: DataContainerStatus.Error,
          payload: requestPaymentError,
          operation: 'markPaymentAsRequested',
        });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, operation: 'markPaymentAsRequested' });
    },

    async deleteDocumentFromPayment(
      { commit },
      {
        paymentId,
        investmentId,
        fileFullPath,
        fileName,
      }: { paymentId: string; investmentId: string; fileFullPath: string; fileName: string },
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'deleteDocumentPayment' });
      const storageRef = bloqifyStorage.ref();
      // eslint-disable-next-line @typescript-eslint/await-thenable
      const fileRef = await storageRef.child(fileFullPath);
      const newFilePath = `${fileFullPath}_deleted_${new Date().toISOString()}`;
      // eslint-disable-next-line @typescript-eslint/await-thenable
      const newFileRef = await storageRef.child(newFilePath);
      const timeNow = firebase.firestore.Timestamp.now();
      const [fileUrlError, fileUrl] = await to(fileRef.getDownloadURL());
      if (fileUrlError) {
        throw fileUrlError;
      }

      const [getFileError, response] = await to(
        axios.get(fileUrl, {
          responseType: 'arraybuffer',
        }),
      );
      if (getFileError) {
        throw getFileError;
      }

      const responseBlob = response.data as Blob;
      const file = new File([responseBlob], fileName);

      const [metadataError, metadata] = await to(fileRef.getMetadata());
      if (metadataError) {
        throw metadataError;
      }

      const [transactionError, transactionSuccess] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<unknown[]> => {
          const investmentRef = bloqifyFirestore.collection('investments').doc(investmentId);
          const paymentRef = investmentRef.collection('payments').doc(paymentId);

          const [readPayment, readPaymentSuccess] = await to(transaction.get(paymentRef));
          if (readPayment || !readPaymentSuccess?.exists) {
            throw readPayment || Error('Error getting the payment.');
          }

          const paymentData = readPaymentSuccess.data();
          const legalDocuments = paymentData?.legalDocuments;

          const legalDocumentToDeleteIndex = legalDocuments.findIndex((v): boolean => v.includes(fileName));

          const newLegalDocuments = [...legalDocuments];
          if (legalDocumentToDeleteIndex !== -1) {
            newLegalDocuments.splice(legalDocumentToDeleteIndex, 1);

            // Update payment legalDocuments
            transaction.update(paymentRef, { legalDocuments: newLegalDocuments, updatedDateTime: timeNow });

            // Soft delete. Files get renamed to <filename>_deleted_<timestamp>, and added deleted=true
            // See https://github.com/bloqhouse/bloqadmin/pull/2212
            // @ts-expect-error - Wrong types?
            newFileRef.put(file, { ...metadata, customMetadata: { deleted: true } });

            await fileRef.delete();
          }

          return newLegalDocuments;
        }),
      );

      if (transactionError) {
        return commit(SET_PAYMENT, {
          status: DataContainerStatus.Error,
          payload: transactionError,
          operation: 'deleteDocumentPayment',
        });
      }

      return commit(SET_PAYMENT, {
        status: DataContainerStatus.Success,
        operation: 'deleteDocumentPayment',
        payload: transactionSuccess,
      });
    },
    async uploadDocumentToPayment(
      { commit },
      { uploadedFiles, paymentId, investmentId }: { uploadedFiles: unknown[]; paymentId: string; investmentId: string },
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'uploadDocumentToPayment' });
      const storageRef = bloqifyStorage.ref();
      const timeNow = firebase.firestore.Timestamp.now();

      const [transactionError, transactionSuccess] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const investmentRef = bloqifyFirestore.collection('investments').doc(investmentId);
          const paymentRef = investmentRef.collection('payments').doc(paymentId);

          const [readPayment, readPaymentSuccess] = await to(transaction.get(paymentRef));
          if (readPayment || !readPaymentSuccess?.exists) {
            throw readPayment || Error('Error getting the payment.');
          }
          const paymentData = readPaymentSuccess.data();
          const legalDocuments = paymentData?.legalDocuments || [];
          const investorId = paymentData?.investor.id;

          const legalDocumentsPaths: string[] = [];
          const storageChildren: { file: File; ref: firebase.storage.Reference }[] = [];

          // Building propper objects: asset (to send to the database) and files (to send to storage)
          // Setting up an array with all the files to be uploaded
          uploadedFiles.forEach((uploadedFile): void => {
            // @ts-expect-error - ToDo: fix types
            const { file } = uploadedFile;
            const fullPath = `payments/${paymentId}/${investorId}/legalDocuments/${file.name}`;

            storageChildren.push({
              file,
              ref: storageRef.child(fullPath),
            });

            legalDocumentsPaths.push(fullPath);
          });

          const newLegalDocuments = legalDocuments.concat(legalDocumentsPaths);

          transaction.update(paymentRef, { legalDocuments: newLegalDocuments, updatedDateTime: timeNow });

          // Uploading all files including hashes
          try {
            await Promise.all(
              storageChildren.map(async (child): Promise<firebase.storage.UploadTask> => {
                const md5Hash = await generateFileMd5Hask(child.file, true);

                return child.ref.put(child.file, { customMetadata: { md5Hash } });
              }),
            );
          } catch (e) {
            return commit(SET_PAYMENT, {
              status: DataContainerStatus.Error,
              payload: e,
              operation: 'uploadDocumentToPayment',
            });
          }

          return newLegalDocuments;
        }),
      );

      if (transactionError) {
        return commit(SET_PAYMENT, {
          status: DataContainerStatus.Error,
          payload: transactionError,
          operation: 'uploadDocumentToPayment',
        });
      }

      return commit(SET_PAYMENT, {
        status: DataContainerStatus.Success,
        operation: 'uploadDocumentToPayment',
        payload: transactionSuccess,
      });
    },
    async transferPayment(
      { commit },
      {
        originInvestorId,
        destinyInvestorId,
        assetId,
        transferDate,
        newBoughtSharesTotal,
        newPaidEuroTotal,
        inputDividens,
      }: CreateTransferPaymentPayload,
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'transferPayment' });
      const [transactionError, transactionSuccess] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          // Collection references
          const assetsRef = bloqifyFirestore.collection('assets').doc(assetId);
          const investorsOriginRef = bloqifyFirestore.collection('investors').doc(originInvestorId);
          const investorsDestinyRef = bloqifyFirestore.collection('investors').doc(destinyInvestorId);
          const paymentDate = firebase.firestore.Timestamp.fromMillis(transferDate);
          // Investments  destiny ref
          let destinyInvestmentRef: firebase.firestore.DocumentReference | undefined;

          // Date now
          const dateNow = firebase.firestore.Timestamp.now();

          // Get origin investment data
          const [queryOriginInvestmentError, queryOriginInvestmentSnapshot] = await to(
            bloqifyFirestore
              .collection('investments')
              .where('investor', '==', investorsOriginRef)
              .where('asset', '==', assetsRef)
              .get(),
          );
          if (queryOriginInvestmentError || queryOriginInvestmentSnapshot.empty) {
            throw queryOriginInvestmentError || new Error('Origin investment not found');
          }
          // Investments  origin ref
          const investmentOriginRef = queryOriginInvestmentSnapshot.docs[0].ref;
          // Investments  origin data
          const investmentOriginData = queryOriginInvestmentSnapshot.docs[0].data() as Investment;

          // Security check
          if (
            newBoughtSharesTotal >
              new BigNumber(investmentOriginData.boughtSharesTotal || 0)
                .minus(investmentOriginData.totalSharesRepayments || 0)
                .toNumber() ||
            newPaidEuroTotal >
              new BigNumber(investmentOriginData.paidEuroTotal || 0)
                .minus(investmentOriginData.totalEuroRepayments || 0)
                .toNumber()
          ) {
            throw new Error('Error in the number of shares or euros');
          }

          // Asset data
          const [assetSnapshotError, assetSnapshot] = await to(transaction.get(assetsRef));
          if (assetSnapshotError || !assetSnapshot.exists) {
            throw assetSnapshotError || new Error('Asset not found');
          }
          const asset = assetSnapshot.data() as Asset;

          // Check dividends number
          const divArray: number[] = asset.dividendsFormat.map((row): number => row.contents[1]);
          const inputDivNum = inputDividens[1];
          if (!divArray.includes(inputDivNum)) {
            throw new Error('Dividends format error');
          }

          // Check investors origin
          const [investorsOriginSnapshotError, investorsOriginSnapshot] = await to(transaction.get(investorsOriginRef));
          if (investorsOriginSnapshotError || !investorsOriginSnapshot.exists) {
            throw investorsOriginSnapshotError || new Error('Origin investor not found');
          }

          // Check investors destiny
          const [investorsDestinySnapshotError, investorsDestinySnapshot] = await to(
            transaction.get(investorsDestinyRef),
          );
          if (investorsDestinySnapshotError || !investorsDestinySnapshot.exists) {
            throw investorsDestinySnapshotError || new Error('Destiny investor not found');
          }

          // Get destiny investment data
          const [queryDestinyInvestmentError, queryDestinyInvestment] = await to(
            bloqifyFirestore
              .collection('investments')
              .where('investor', '==', investorsDestinyRef)
              .where('asset', '==', assetsRef)
              .get(),
          );
          if (queryDestinyInvestmentError) {
            throw queryDestinyInvestmentError || new Error('Destiny investor data not found');
          }

          // Create destiny investment if not exists
          if (queryDestinyInvestment.empty) {
            const newInvestmentData: Investment = {
              deleted: false,
              asset: assetsRef,
              investor: investorsDestinyRef,
              createdDateTime: dateNow,
              boughtSharesTotal: newBoughtSharesTotal,
              paidEuroTotal: newPaidEuroTotal,
              paidPayments: 1,
              signChoice: 'unknown',
              updatedDateTime: dateNow,
            };

            const newInvestmentRef = bloqifyFirestore.collection('investments').doc();
            transaction.set(newInvestmentRef, newInvestmentData);

            destinyInvestmentRef = newInvestmentRef;
          } else {
            const investmentDestinyRef = queryDestinyInvestment.docs[0].ref;
            const investmentDestinyData = queryDestinyInvestment.docs[0].data() as Investment;
            // Update destiny investment values
            const updatedPaidEuroTotalDestiny = new BigNumber(investmentDestinyData.paidEuroTotal || 0)
              .plus(newPaidEuroTotal)
              .toNumber();
            const updatedBoughtSharesTotalDestiny = new BigNumber(investmentDestinyData.boughtSharesTotal || 0)
              .plus(newBoughtSharesTotal)
              .toNumber();
            const updatedPaidPayments = new BigNumber((investmentDestinyData.paidPayments as number) || 0)
              .plus(1)
              .toNumber();

            transaction.update(investmentDestinyRef, {
              boughtSharesTotal: updatedBoughtSharesTotalDestiny,
              paidEuroTotal: updatedPaidEuroTotalDestiny,
              paidPayments: updatedPaidPayments,
              updatedDateTime: dateNow,
            } as Investment);

            destinyInvestmentRef = investmentDestinyRef;
          }

          // Payments destiny ref
          const newPaymentDestinyDocRef = destinyInvestmentRef.collection('payments').doc();
          // Create repayment data for origin
          const repaymentRef = investmentOriginRef.collection('repayments').doc();
          const repaymentData: InvestmentRepayment = {
            amount: newPaidEuroTotal,
            shares: newBoughtSharesTotal,
            createdDateTime: dateNow,
            updatedDateTime: dateNow,
            deleted: false,
            asset: assetsRef,
            investment: investmentOriginRef,
            investor: investorsOriginRef,
            repaymentDateTime: paymentDate,
            paymentDateTime: paymentDate,
            description: 'transfer',
            dividendsFormat: inputDividens,
            to: newPaymentDestinyDocRef,
          };

          transaction.set(repaymentRef, repaymentData);

          // Create payment data for destiny
          const newPaymentData: Payment = {
            provider: PaymentProvider.Custom,
            providerData: {
              id: newPaymentDestinyDocRef.id,
              amount: {
                value: newPaidEuroTotal.toString(),
                currency: 'EUR',
              },
              status: PaymentStatus.Paid,
              metadata: {
                euroAmount: newPaidEuroTotal,
                sharesAmount: newBoughtSharesTotal,
                investmentId: investmentOriginData.investor.id,
                assetId,
                paymentId: newPaymentDestinyDocRef.id,
                uid: investorsDestinyRef.id,
              },
            },
            investment: destinyInvestmentRef,
            investor: investorsDestinyRef,
            asset: assetsRef,
            deleted: false,
            legalDocuments: [],
            createdDateTime: dateNow,
            updatedDateTime: dateNow,
            transactionDate: paymentDate,
            paymentDateTime: paymentDate,
            from: repaymentRef,
            dividendsFormat: inputDividens,
          };

          transaction.set(newPaymentDestinyDocRef, newPaymentData);

          // Update origin investment values
          const updatedTotalEuroRepayments = new BigNumber(investmentOriginData.totalEuroRepayments || 0)
            .plus(newPaidEuroTotal)
            .toNumber();

          const updatedTotalSharesRepayments = new BigNumber(investmentOriginData.totalSharesRepayments || 0)
            .plus(newBoughtSharesTotal)
            .toNumber();

          transaction.update(investmentOriginRef, {
            totalEuroRepayments: updatedTotalEuroRepayments,
            totalSharesRepayments: updatedTotalSharesRepayments,
            updatedDateTime: dateNow,
          } as Investment);
        }),
      );
      if (transactionError) {
        return commit(SET_PAYMENT, {
          status: DataContainerStatus.Error,
          payload: transactionError,
          operation: 'transferPayment',
        });
      }
      return commit(SET_PAYMENT, {
        status: DataContainerStatus.Success,
        payload: transactionSuccess,
        operation: 'transferPayment',
      });
    },
  },
  getters: {},
} as Module<Vertebra, State>;
