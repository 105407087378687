// https://github.com/benmosher/eslint-plugin-import/issues/2065
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';

const bloqifyConfig = {
  apiKey: process.env.VUE_APP_BLOQIFY_FIREBASE_API_KEY!,
  authDomain: process.env.VUE_APP_BLOQIFY_FIREBASE_AUTH_DOMAIN!,
  databaseURL: process.env.VUE_APP_BLOQIFY_FIREBASE_DATABASE_URL!,
  projectId: process.env.VUE_APP_BLOQIFY_FIREBASE_PROJECT_ID!,
  storageBucket: process.env.VUE_APP_BLOQIFY_FIREBASE_STORAGE_BUCKET!,
  messagingSenderId: parseInt(process.env.VUE_APP_BLOQIFY_FIREBASE_MESSAGING_SENDER_ID, 10),
};
type BloqifyConfig = { [key in keyof typeof bloqifyConfig]: string };

const bloqify = firebase.initializeApp(bloqifyConfig);

const bloqifyAuth = bloqify.auth();
const bloqifyStorage = bloqify.storage();
const bloqifyFirestore = bloqify.firestore();

// Special region for local test
const bloqifyFunctions: firebase.functions.Functions = bloqify.functions(
  process.env.VUE_APP_BLOQIFY_FIREBASE_CLOUD_FUNCTIONS_REGION,
);

// Emulator for local tests
if (process.env.NODE_ENV === 'development') {
  /* bloqifyFunctions.useEmulator('localhost', 5002);
  bloqifyFirestore.useEmulator('localhost', 8081);
  bloqifyAuth.useEmulator('http://localhost:9099');
  bloqifyStorage.useEmulator('localhost', 9199); */
}

export { bloqify, BloqifyConfig, bloqifyFirestore, bloqifyAuth, bloqifyFunctions, bloqifyStorage, firebase };
